
@font-face {
  font-family: "Arial";   /*Can be any text*/
  src: local("arial"),
    url("./assets/fonts/arial.ttf") format("truetype");
}

@font-face {
  font-family: "ArialBold";   /*Can be any text*/
  src: local("arialbd"),
    url("./assets/fonts/arialbd.ttf") format("truetype");
}

:root {
  --mainBgColor: #f0f0f0;
  --stw: 1674px;
  --sth: 900px;
  --textAreaOut: 1200px;
  --textAreaBorderWidth: 4px;
  --textAreaBorderColor: #43525a;
/*  --titleTextColor:rgba(0, 0, 0, 0.46);
  --trafficRed:rgb(251,63,63);
  --trafficYellow:rgb(255, 226, 3);
  --trafficGreen:rgb(24,190,0);
  --trafficRedSelected:#980303;
  --trafficYellowSelected:rgb(255, 179, 3);
  --trafficGreenSelected:#128201; */

  --catTableColorRed:#d50505;
  --catTableColorYellow:#eeb21b;
  --catTableColorGreen:#00ac4e;

  --catTableColorRedSelected:#980303;
  --catTableColorYellowSelected:#d19304;
  --catTableColorGreenSelected:#128201;

  --textColor:#43525a;
  --textAreaFontSize: 40px;
  --tableBorederWidth: 8px;
}

html,
body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    background: #ccff02;
    overscroll-behavior: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering:optimizeLegibility;
    -webkit-tap-highlight-color: transparent;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
#wrapper {
 display:flex;
 justify-content: center;
 align-items:center;
  width: var(--stw);
  height: var(--sth);
  transform-origin: 0 0;
/*  height: 100%;
  width: 100%;  */
/*  max-height: 900px;
  
  max-width: 1674px; */
  font-family: 'ArialBold', sans-serif;
  margin: 0;
  padding: 0;
  background: #fff;
  font-size: 16px;
  align-items:center;
  justify-content:center;
}

#main {
  position:relative;
 /* display: flex;
  justify-content:center;
  background-image:url('./assets/bg.png');*/
  background-repeat:no-repeat;
  width: var(--stw);
  height: var(--sth);
/* margin: 5px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;*/
  font-family: 'ArialBold';

}

#overlay {
  position: absolute;
  display: flex;
  justify-content:center;
  /*background-color: var(--mainBgColor);*/
  background-image:url('./assets/Blue_Background.png');
  width: var(--stw);
  height: var(--sth);
 /* border-radius: 15px;*/
}

#modalWrap {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000000;
  font-family: 'ArialBold', sans-serif;
  display: flex;
  flex-direction: column;
}

#modalContent {
  background:white;
  width: 100%;
  max-width: 600px;
  padding: 0 0;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  border: 1px solid rgb(0,0,0);
}

#modalBody {
  background-color: #fff;
  padding: 10px 0px 0px 0px;
  margin: 0 0 0 0;
  text-align: center;
  font-size: 18px;
}

.yesnobtns {
  width:100%;
  height: 50px;
}

.btnyes {
  width: 50%;
  height: 100%;
  margin-bottom:0;
  background-color:var(--catTableColorGreen);
  font-size:25px;
}

.btnno {
  width: 50%;
  height: 100%;
  margin-bottom:0;
  font-size:25px;
}

.StartPage {
  display:flex;
  justify-content:space-between;
  align-items:center;
  top:0;
  left:0;
  width: var(--stw);
  height: var(--sth);
/*  flex-wrap: wrap; 
  background-color:transparent;
  align-items:center;
  justify-content:center; */

  /* prevents vertical streching 
  align-self:center;
  overflow:hidden;
   color:var(--titleTextColor); */
}

.StartPage .StartText {
  position: relative;
  top: 18px;
  left: 150px;
/*  justify-content:center; 
 object-fit:contain;
  width: 400px;
  height: 400px;
  margin: 5px;*/
  background-color: transparent;
 
}

.StartPage .IntroLogo {
  position: absolute;
  left: calc(var(--stw) - 300px);
  top: calc(var(--sth) - 240px);
}

.shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}
/*
.StartPage div {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
}*/

button.start {
  width: 320px;
  height: 322px;
  border-radius: 100px;
  border-width: 0px;
  background-color:transparent;
  margin-right:170px;
  cursor:pointer;
  object-fit:fill;
}

button.nav {
  display:flex;
  justify-content:center;
  align-items:center;
  font-size: 50px;
  font-weight: 600;
  color: #fff;
  width: 60px;
  height: 60px;
  border: 0px;
  background-color:black;
  border-radius: 60px;
  margin-top:20px;
  margin-bottom:20px;
  cursor:pointer;
  text-align:center;
  font-family: 'ArialBold';
}

.RecognizePage {
  display: flex;
  flex-direction: column;
  background-color:transparent;
  align-items:center;
  justify-content:center;
 /* width:max-content;*/
  top:0;
  left:0;
  width: var(--stw);
  height: var(--sth);

  /* prevents vertical streching */
  align-self:center;
}

.trafficAndTitle {
   display:flex;
  /* flex-wrap: wrap; */
  align-items:center;
   justify-content:space-evenly;
/*   margin-left: auto;
   margin-right: auto;*/
   width: var(--textAreaOut);
   margin: auto;
}
.RecognizePage .TextAreaOut {
  display: flex;
  width: var(--textAreaOut);
  height: 450px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items:center;
  align-content:center;
  justify-content:center;
  background-color:white;
  margin-top: 10px;
}

.RecognizePage textarea {
  width: 100%;
  height: 100%;
  font-size: var(--textAreaFontSize);
  min-height: 80px;
  border-radius: 15px;
  /*border-color: rgba(0, 0, 0, 1);
  border-width: 1px;*/
  border: var(--textAreaBorderWidth) solid var(--textAreaBorderColor); 
  padding: 20px;
  resize:none;
  color:var(--textColor);
}

.RecognizePage .bottom {
  display: flex;
  flex-wrap: wrap;
  width: var(--textAreaOut);
  justify-content:space-between;
}

.trafficAndTitle .title {
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width: 900px;
  /*margin-left: 10px;*/

}

.trafficAndTitle .title .title1 {
/*  font-size: 35px;
  color:var(--titleTextColor); */
  margin-top: 5px;
  height:100px;
}

.trafficAndTitle .title .title2 {
 /* font-size: 22px;
  color:var(--titleTextColor); */
  margin-bottom: 5px;
  height:80px;
}

button.start:disabled {
  /*background-color: rgba(0, 0, 0, 0.5);*/
  opacity: 0.5;
  cursor:default;
}

button.nav.recognize{
  height: 40px;
  width:100px;
  border: 0px;
  background-color:#8b6baf;
  border-radius: 20px;
  cursor:pointer;
}

.trafficLight {
  margin-top: 15px;
  width: 434px;
  height: 193px; 
}

/*
.trafficLight {
  display: flex;
  justify-content:space-around;
  align-items:center;
  width: 200px;
  height: 60px;
  border: 6px solid rgb(123, 122, 122);
  border-radius: 15px;
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,0.7747431736366421) 0%, rgba(0,0,0,1) 72%, rgba(0,0,0,0.9932305685946253) 100%);
}

.trafficLight .off {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background-color:darkgray;
  border: 5px solid rgb(22, 21, 21);
}

.trafficLight .red {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background: var(--trafficRed);
  background: radial-gradient(circle, rgba(251,63,63,0.8) 0%, rgba(252,70,70,1) 100%);
  border: 5px solid rgb(22, 21, 21);
}

.trafficLight .yellow {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background: var(--trafficYellow);
  background: radial-gradient(circle, rgba(247,255,3,0.8) 0%, rgba(217,255,0,1) 100%);  
  border: 5px solid rgb(22, 21, 21);
}

.trafficLight .green {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background: var(--trafficGreen);
  background: radial-gradient(circle, rgba(24,190,0,0.8) 0%, rgba(22,200,0,1) 100%);  
  border: 5px solid rgb(22, 21, 21);
}
*/

.ThinkPlanPage {
  display: flex;
  flex-direction: column;
  width:100%;
  background-color:transparent;
  align-items:center;
  justify-content:center;

  /* prevents vertical streching */
  align-self:center;
  
  overflow: hidden;
  margin-top:10px;
  margin-bottom:10px;
  padding: 10px;
}

button.plusButton {
  display:flex;
  justify-content:center;
  align-items:center;
  width:84px;
  height:84px;
  border-radius:42px;
/*  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;*/
  border: 0px;
  background-color:#00b788;
  cursor: pointer;
  margin-top: 200px;
  margin-left: 170px;
  text-align:center;
}

.ThinkPlanPage .bottom {
  display: flex;
  flex-wrap:wrap;
  width: var(--textAreaOut);
  margin: auto;
  justify-content:space-between;
  align-items:center;
}

button.nav.thinkplan{
  height: 66px;
  width:212px;
  border: 0px;
  background-color:#8b6baf;
  border-radius: 33px;
  cursor:pointer;
}

button.nav.thinkplan:disabled {
  height: 66px;
  width:212px;
  border: 0px;
  background-color:rgba(0,0,0,0.5);
  color: rgba(255,255,255,0.3);
  border-radius: 33px;
  cursor:default;
}

.AnswerInput {
  display: flex;
  flex-direction: column;
  background-color:transparent;
  align-items:center;
  justify-content:center;
  width:max-content;
  /* prevents vertical streching */
  align-self:center;
}


.AnswerInput textarea {
  width: var(--textAreaOut);
  height: 450px;
  font-size: var(--textAreaFontSize);
  color:var(--textColor);
  border-radius: 15px;
  border-color:var(--textAreaBorderColor);
  border-width: var(--textAreaBorderColor);
  padding: 10px;
  resize:none;
}

.AnswerInput .bottom {
  display: flex;
  flex-wrap:wrap;
  width: var(--textAreaOut);
  margin: auto;
  justify-content:space-between;
  align-items:center;
}

.organizePage {
  display: flex;
  flex-direction: column;
  background-color:transparent;
  align-items:center;
  justify-content:center;
  margin-top:20px;
  margin-bottom:20px;
  /* prevents vertical streching */
  align-self:center;
  overflow:hidden;
}

.organizePage .sections {
  display: flex;
   
  /*flex-wrap:wrap;
  width:calc(100% - 60px);
 width: 90%;
 margin-right: 40px;
 margin-left: 20px;
 min-height:100px;*/
}

.organizePage .sectionsGrid {
  display: grid;
  grid-auto-flow: column;
 width: 1319px;
 height: 527px;
 overflow:hidden;
 resize:none;
}

.organizePage .sectionsGrid1 {
  display: grid;
  grid-auto-flow: column;
 width: 1319px;
 height: 417px;
 overflow:hidden;
 resize:none;
}

.categorySection {
  display:flex;
  flex-direction:column;
  min-height: 0;  /* NEW */
  min-width: 0;   /* NEW; needed for Firefox */
  /* max-width: 300px;
  width:33%;
  flex-grow: 1;*/
}

.categorySection .title {
  width:100%;
  display: flex;
  align-items:center;
  justify-content:center;
  height:135px;
  background-color:black;
  color:white;
  resize:none;
 /* vertical-align:middle;
  text-align:center;*/
}

.categorySection .red {
  box-sizing: border-box;
  background-color:var(--catTableColorRed);
  border: var(--tableBorederWidth) solid var(--catTableColorRed);
  overflow-y: auto;
  /* margin-right:calc(var(--tableBorederWidth) * 2);*/
}

.categorySection .red.selected {
  box-sizing: border-box;
  background-color:var(--catTableColorRedSelected);
  border: var(--tableBorederWidth) solid var(--catTableColorRedSelected);
  overflow-y: auto;
/*  margin-right:calc(var(--tableBorederWidth) * 2); */
}

.categorySection .yellow {
  box-sizing: border-box;
  background-color:var(--catTableColorYellow);
  border: var(--tableBorederWidth) solid var(--catTableColorYellow);
  overflow-y: auto;
 /* margin-left:calc(var(--tableBorederWidth) * 2);
  margin-right:var(--tableBorederWidth);*/
}

.categorySection .yellow.selected {
  box-sizing: border-box;
  background-color:var(--catTableColorYellowSelected);
  border: var(--tableBorederWidth) solid var(--catTableColorYellowSelected);
  overflow-y: auto;
/*  margin-left:calc(var(--tableBorederWidth) * 2);
  margin-right:var(--tableBorederWidth); */
}

.categorySection .green {
  box-sizing: border-box;
  background-color:var(--catTableColorGreen);
  border: var(--tableBorederWidth) solid var(--catTableColorGreen);
  overflow-y: auto;
  /* margin-left:calc(var(--tableBorederWidth) * 4);*/
}

.categorySection .green.selected {
  box-sizing: border-box;
  background-color:var(--catTableColorGreenSelected);
  border: var(--tableBorederWidth) solid var(--catTableColorGreenSelected);
  overflow-y: auto;
 /* margin-left:calc(var(--tableBorederWidth) * 4); */
}

.categorySection .body {
  height:100%;
  width:100%;
  background-color:#fff;
}

.categorySection .body.red {
  height:100%;
  width:100%;
  background-color:#fff;
  /*border: var(--tableBorederWidth) solid var(--catTableColorRed);*/
}
.categorySection .body.red.selected {
  height:100%;
  width:100%;
  background-color:#fff;
/*  border: var(--tableBorederWidth) solid var(--catTableColorRedSelected);*/
}

.categorySection .body.yellow {
  height:100%;
  width:100%;
  background-color:#fff;
/*  border: var(--tableBorederWidth) solid var(--catTableColorYellow); */
}
.categorySection .body.yellow.selected {
  height:100%;
  width:100%;
  background-color:#fff;
/*  border: var(--tableBorederWidth) solid var(--catTableColorYellowSelected); */
}

.categorySection .body.green {
  height:100%;
  width:100%;
  background-color:#fff;
 /* border: var(--tableBorederWidth) solid var(--catTableColorGreen);*/
}
.categorySection .body.green.selected {
  height:100%;
  width:100%;
  background-color:#fff;
 /* border: var(--tableBorederWidth) solid var(--catTableColorGreenSelected);*/
}

.organizePage .bottom {
  display: flex;
  flex-direction:row;
  width: var(--textAreaOut);
  margin: auto;
  justify-content:space-between;
}

.answerBubblePreview {
  position:relative;
  /*width:fit-content;*/
  min-width:180px;
  max-width:300px;
   margin: 5px;
 /* margin-top:10px;
  margin-right:10px;*/
}

.answerBubblePreview .bubble {
 /* display:flex;
  flex-direction:row;*/
  align-items:center;
  min-width:150px;
  height:80px;
  overflow-y:auto;
  border: var(--textAreaBorderWidth) solid var(--textAreaBorderColor);
  border-radius:25px;
  background-color:white;
  padding: 15px; 
  word-wrap:break-word;
  /*word-break:break-all;*/
  font-size: 22px;
  line-height:22px;
  text-align:left;
  color:var(--textColor);
}

.organizePage .bubbleTop {
  display:flex;
  justify-content:center;
  align-items:top;
  width:100%;
  height:140px;
}

.organizePage .bubbleTop1 {
  display:flex;
  justify-content:center;
  align-items:top;
  width:100%;
}

.answerBubble {
  position:relative;
  margin: 5px;
  width:300px;
/*  margin-bottom: 20px;
  max-width:300px;
  margin-top:10px;
  margin-right:10px;*/
}

.answerBubble .bubble {
  align-items:center;
  min-width:150px;
  height: 80px;
  overflow-y:auto;
  border: var(--textAreaBorderWidth) solid var(--textAreaBorderColor);
  border-radius:25px;
  background-color:white;
  padding: 15px; 
  word-wrap:break-word;
  /*word-break:break-all;*/
  font-size: 22px;
  line-height:25px;
  text-align:left;
  color:var(--textColor);
}

.answerBubble .ghost {
  min-width:150px;
  height:80px;
  border-radius:25px;
  opacity:0;
  background-color:rgba(0,0,0,0.1);
  padding: 5px; 
}

.answerBubbleGreen {
 /* display:flex;
  flex-direction:row;*/
  align-items:center;
  min-width:150px;
  height:80px;
  overflow-y:auto;
  border: var(--textAreaBorderWidth) solid var(--textAreaBorderColor);
  border-radius:25px;
  background-color:var(--catTableColorGreen);
  padding: 15px; 
  word-wrap:break-word;
  /*word-break:break-all;*/
  font-size: 22px;
  line-height:22px;
  text-align:left;
  color:var(--textColor);
}

.answerBubble .close {
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top:-8px;
  right:-8px;
  width:20px;
  height:20px;
  line-height:20px;
  border-radius:20px;
  font-size: 10px;
  color: #fff;
  background-color:black;
  cursor: pointer;
  text-align:center;
}

.tryPage {
  display: flex;
  flex-direction: column;
  width:100%;
  background-color:transparent;
  align-items:center;
  justify-content:center;
  /* prevents vertical streching */
  align-self:center;
}

.tryPage .bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  width: var(--textAreaOut);
  justify-content:space-between;
}

.tryPage .dilemma {
  display: flex;
  flex-direction: column;
  width: var(--textAreaOut);
  height: 132px;
  justify-content:flex-start;
  align-items:flex-start;
  /* color:var(--titleTextColor); */
  background-color:#ecedee;
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 15px;
  overflow-y:auto;
  resize:none;
}

.tryPage .solution {
  display: flex;
  flex-direction: column;
  width: var(--textAreaOut);
  height: 350px;
  justify-content:flex-start;
  align-items:flex-start;
  /* color:var(--titleTextColor); */
  background-color:#ecedee;
  padding: 15px;
  overflow-y:auto;
  resize:none;
}

.tryPage .solution .solutionText {
  /* color:var(--titleTextColor); 
  border-radius: 15px;
  background-color:var(--catTableColorGreen);*/
  padding: 15px;
  font-size: var(--textAreaFontSize);
  color:var(--textColor);
  word-wrap:break-word;
  /*word-break:break-all;*/
}

.tryPage .dilemma .dilemmaText {
  /* color:var(--titleTextColor); 
  border-radius: 15px;
  background-color:var(--catTableColorGreen);*/
  padding: 15px;
  font-size: var(--textAreaFontSize);
  color:var(--textColor);
  word-wrap:break-word;
  /*word-break:break-all;*/
}

.tryPage .solution .solutionTitle {
  margin-left: 10px;
  color:var(--textColor);
  font-size: var(--textAreaFontSize);
}

.tryPage .dilemma .dilemmaTitle {
  margin-left: 10px;
  color:var(--textColor);
  font-size: var(--textAreaFontSize);
}
